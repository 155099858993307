export default function useCurrency() {
  const { locale } = useI18n();

  const currency = ref<"CZK" | "EUR" | "USD">(
    locale.value === "en" ? "USD" : "CZK",
  );

  useUserQuery().then(({ data }) => {
    if (data.value?.currency) {
      currency.value = data.value.currency;
    }
  });

  return {
    currency,
  };
}
